<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()">我的课程/课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程查看</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            
            <div style="display:flex;justify-content: flex-end;">
              <el-button
                v-show="
                  courseOrigin != '20'
                "
                size="mini"
                class="bgc-bv"
                :disabled="auditState =='30'"
                @click="doEdit"
                >编辑</el-button
              >
              <el-button size="mini" class="bgc-bv" @click="$router.back()"
                >返回</el-button
              >
            </div>
            <h3 class="flexcb doedit" style="padding-bottom: 20px">
              <span>课程基本信息</span>
            </h3>
            <div class="form-detail">
              <el-form :inline="true" ref="ruleForm" label-width="10rem">
                <el-form-item label="课程名称：" class="form-inline">
                  <p>{{ ruleForm.courseName }}</p>
                </el-form-item>
                <el-form-item
                  label="培训类型："
                  class="form-inline"
                >
                  <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
                </el-form-item>
                </el-form>
                <el-form  ref="ruleForm" label-width="10rem">
                  <el-form-item
                  label="课程详情："
                  prop="context"
                >
                  <div
                    v-html="ruleForm.context"
                    ref="editor"
                    style="width: 100%; position: relative; z-index: 2"
                  />
                </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <div class="img-el-upload" style="width: 256px">
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                    ></el-image>
                  </div>
                </el-form-item>
              </el-form>
            </div>

            <h3 style="padding-bottom: 20px">课程章节</h3>
            <div style="display: flex">
              <div style="flex: 1">
                 <div style="display: flex; color: red">
              <p style="padding: 10px">课件总数:{{ ruleForm.kpointTotalNum }}</p>
              <p style="padding: 10px">视频累计时长:{{ ruleForm.durationStr }}</p>
             
            </div>
                <section class="chapterl-trees detail">
                  <el-tree
                    :data="data"
                    node-key="id"
                    ref="tree"
                    default-expand-all
                    v-if="data"
                  >
                    <div
                      class="custom-tree-node flexac"
                      slot-scope="{ node, data }"
                    >
                      <p v-if="data.level === 1">
                        <span style="width:44px;text-align: left;">
                          <span class="level" style="margin-right:10px;">
                            <!-- 一级 -->
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span>{{ node.label }}</span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <span style="width:44px;text-align: left;">
                          <span class="level" style="margin-right:10px;">
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span
                          v-if="data.list.length > 0"
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i
                            class="courseIcon"
                            v-if="data.list[0].kpointId"
                          ></i>
                          <i
                            class="paperIcon"
                            v-else-if="data.list[0].paperId"
                          ></i>
                        </span>
                        <span
                          v-else
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i class="nullIcon"></i>
                        </span>
                        <span class="ellipsis" style="margin-right: 62px">{{
                          data.label
                        }}</span>
                        <span>{{data.kpointLessonNum}}学时</span>
                        <span style="margin-left:10px">{{ data.kpointDurationStr }}</span>
                        <span class="trees-btns" :canDarg="data.list.length">
                          <template v-if="data.list.length">
                            <span
                              class="draggable-item handle"
                              v-for="element in data.list"
                              :key="element.name"
                            >
                              <!-- <em @click.stop="() => showVideo(data)">预览</em> -->
                              <el-button
                                class="showbtn bgc-bv"
                                size="mini"
                                @click.stop="() => showVideo(data)"
                                >预览</el-button
                              >
                            </span>
                          </template>
                        </span>
                        <span
                          v-if="data.list[0] && data.list[0].kpointDeleted"
                          style="color: #f46173; margin-left: 30px"
                          >课件已被删除</span
                        >
                      </template>
                     
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
        ref="player2"
      />
      <player_1
        :watermark="true"  
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
        ref="player2"
      />
    </el-dialog>
   
  </div>
</template>
<script>
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
import { mapGetters } from "vuex";
export default {
  name: "detailResource",
  components: {
    player_1,
    player_2,
  },
  data() {
    return {
      courseId: this.$route.query.courseId,
      courseOrigin: this.$route.query.courseOrigin,
      auditState: this.$route.query.auditState,
      styleType: "",
      ruleForm: {
        resource: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        trainTypeNamePath: "",
        lecturer: "",
      },
      lecture: "",
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      styleStu: false,
      chapterSchema: true,
      textareaDisabled: true,
      saveShow: false,
      editShow: true,
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      dialogVisible: false,
      allTreeState:false,//一键展开/折叠
    };
  },
   computed: {
      ...mapGetters({
        userJson: "getUser"
      })
    },
  created() {
    this.stu = this.$route.query.stu;
    if (this.stu == 'query') {
      this.editShow = false;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        item.children.forEach((el, k) => {
          el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
        });
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    init() {
      this.getDetail();
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player2.dd();
      distorySwigger();
    },
    getDetail() {
      this.$post("/biz/course/display",
        {
          courseId: this.courseId,
        }
      ).then((result) => {
        if (result.status === "0") {
          let data = result.data;
          const ret = {
            agreement: data.salesState == "10" ? false : true,
            courseName: data.courseName,
            context: data.context,
            thumbnailId: data.logo,
            salesState: data.salesState,
            thumbnail: data.logoURL,
            lecturer: data.lecturer,
            resource: "",
            trainTypeNamePath: data.trainTypeNamePath,
            postName: data.postName,
            industryNamePath: data.industryNamePath,
            occupationNamePath: data.occupationNamePath,
            trainLevelName: data.trainLevelName,
            auditReason: data.auditReason,
            auditState: data.auditState,
            pushState: data.pushState,
            courseOrigin: data.courseOrigin,
            durationStr: data.durationStr,
            totalClassHours: data.totalClassHours,
            lessonNum:data.lessonNum,
kpointLessonNum:data.kpointLessonNum,
kpointTotalNum:data.kpointTotalNum
          };
          this.styleType = data.chapterSchema ? 1 : 2;
          this.lecture = ret.lecturer;
          this.ruleForm = ret;
          let list = [];
          if (data.chapterSchema) {
            data.nodes.forEach((element) => {
              let nodeList = [];
              list.push({
                ind: new Date().getTime(),
                label: element.catalogName,
                id: element.courseKpointId,
                children: nodeList,
                level: 1,
                kpointDuration: element.kpointDuration,
                kpointDurationStr: element.kpointDurationStr,
                kpointLessonNum: element.kpointLessonNum,
              });
              (element.child || []).forEach((obj) => {
                nodeList.push(this.section2Item(obj));
              });
            });
          } else {
            data.nodes.forEach((element) => {
              list.push(this.section2Item(element));
            });
          }
          this.data = list;

          this.mapData(list);
        }
      });
    },
    section2Item(obj) {
      let children = [];
      if (this.stu == 'edits') {
            children = [];
      } else {
          children = [];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted ? obj.kpointDeleted : "", // 课件是否删除了
          progress: 100,
          kpointSource: obj.kpointSource,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          kpointLessonNum: obj.kpointLessonNum,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: children,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
          kpointId: obj.kpointId,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          kpointLessonNum: obj.kpointLessonNum,
        };
      } else {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
        };
      }
    },
    // 预览
    showVideo(data) {
      console.log(data);
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        // console.log('11');
        // var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
        // var objExp = new RegExp(Expression);
        // if (objExp.test(_this.videoId)) {
        //   _this.videoId = data.list[0].kpointVideoId;
        //   _this.courseId = _this.courseId;
        //   _this.kpointId = data.kpointId;
        //   _this.kpointSource = data.list[0].kpointSource;
        //   _this.dialogVisible1 = true;
        // } else {
          _this.$post("/biz/courseware/auth", {
              videoId: data.list[0].kpointVideoId,
            })
            .then((result) => {
              _this.videoId = data.list[0].kpointVideoId;
              _this.videoType = result.data.trySee;
              _this.videoTime = result.data.duration;
              _this.videotitle = data.label;
              _this.courseId = _this.courseId;
              _this.kpointId = data.kpointId;
              _this.kpointSource = data.list[0].kpointSource;
              console.log(data.kpointId);
              _this.dialogVisible1 = true;
            });
        // }
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        _this.showPaper(data);
      }
    },
    //编辑
    doEdit() {
    //  if (this.ruleForm.salesState =='20') {
    //      this.$message({
    //       message: "请先下架该课程再进行编辑！",
    //       type: "error",
    //     });
    //   } else {

        let path = "/web/CustomPrograms/enterpriseInternalTrainingUploadMyCourse";
        this.$router.replace({
          path,
          query: {
            courseId: this.courseId,
            courseOrigin: this.courseOrigin,
          },
        });
    //   }
    },
    goHerf() {
      this.$router.push({
        path: "/web/CustomPrograms/enterpriseInternalTrainingMyCourseCourseWare",
        query: {
          refresh: true,
          isAdd: true,
        },
      });
    },
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
  },
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }
  .form-inline {
    width: 45%;
    .el-form-item__content {
      // width: 100%;
      p {
        width: 15rem;
      }
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.detail {
  .ellipsis {
    display: inline-block;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}
.doedit {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #6676ff;
  }
}
.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scope>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
</style>
